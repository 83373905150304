import { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import DeleteQuestion from './deleteQuestion';

type DeleteModalProps = {
  modalIsOpen: boolean;
  loading: boolean;
  closeModal: () => void;
  handleDelete: () => void;
};

const DeleteModal: FC<DeleteModalProps> = ({ modalIsOpen, loading, closeModal, handleDelete }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <Modal
      shouldCloseOnOverlayClick
      isOpen={modalIsOpen}
      style={{ content: { width: 350, height: 387, overflow: 'hidden', background: 'white' } }}
      contentLabel={formatMessage({ id: 'quick.view', defaultMessage: 'Quick view' })}
      onRequestClose={closeModal}
    >
      <div className="flex h-full flex-col justify-between gap-24 px-20 py-32">
        <div className="flex flex-col items-center gap-32">
          <DeleteQuestion />
          <div className="flex flex-col gap-12">
            <Typography className="text-28 font-bold leading-[35px] text-neutral-4">
              {`${formatAccountMessage({ id: 'delete.address', defaultMessage: 'Delete address' })}?`}
            </Typography>
            <Typography className="text-16 leading-[20px] text-neutral-4">
              {formatAccountMessage({ id: 'action.warning', defaultMessage: 'This action can not be undone.' })}
            </Typography>
          </div>
        </div>
        <div className="flex gap-24">
          <div className="basis-1/2">
            <Button variant="primary" className="w-full" type="submit" loading={loading} onClick={handleDelete}>
              {formatMessage({ id: 'save', defaultMessage: 'Save' })}
            </Button>
          </div>
          <div className="basis-1/2">
            <Button variant="secondary" className="w-full" type="button" onClick={closeModal}>
              {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
