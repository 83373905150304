import { useCallback, useEffect, useMemo, useState } from 'react';
import { Address } from 'shared/types/account/Address';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Typography from 'components/commercetools-ui/atoms/typography';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import TrashIcon from 'components/icons/TrashIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useProcessing from 'helpers/hooks/useProcessing';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { useAccount } from 'frontastic';
import AddressFormFields from './addressFormFields';
import DeleteModal from './deleteModal';
import usePropsToAddressType from './mapPropsToAddressType';
import useDiscardForm from '../../hooks/useDiscardForm';

export interface AddressFormProps {
  addressId?: string;
  editedAddressId?: Address['addressId'];
}

export interface AddressFormData extends Address {
  addressId: string;
  addressType?: 'shipping' | 'billing';
  isDefaultAddress?: boolean;
  isBillingAddress?: boolean;
  isDefaultBillingAddress?: boolean;
  isDefaultShippingAddress?: boolean;
}

export interface AddressFormFields {
  name: string;
  label: string;
  labelDesc: string;
  placeholder?: string;
  tooltip?: string;
  type: React.ComponentProps<'input'>['type'];
  className: string;
  required?: boolean;
  render?: () => JSX.Element;
  validationSchema: (value: string) => { isValid: boolean; error?: string };
}

const AddressForm: React.FC<AddressFormProps> = ({ editedAddressId }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  const { processing, startProcessing, stopProcessing } = useProcessing();
  const { removeAddress, account } = useAccount();
  const { mapPropsToAddress } = usePropsToAddressType();
  const { discardForm } = useDiscardForm();

  const [loadingDelete, setLoadingDelete] = useState(false);

  const [saveAsDefault, setSaveAsDefault] = useState(false);

  const defaultData = useMemo(() => {
    if (!editedAddressId) return { addressType: 'shipping' } as AddressFormData;

    const accountAddress = account?.addresses?.find(
      (address) => address.addressId === editedAddressId,
    ) as AddressFormData;

    if (accountAddress) {
      accountAddress.addressType = mapPropsToAddress(accountAddress).addressType;
    }

    return accountAddress;
  }, [account?.addresses, editedAddressId, mapPropsToAddress]);

  const [data, setData] = useState<AddressFormData>(defaultData);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  const [isValidForm, setIsValidForm] = useState(false);

  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });
  const postalCodeError = formatErrorMessage({
    id: 'form.postalCode',
    defaultMessage: 'The postal code must be 5 digits long',
  });
  const phoneNumberError = formatErrorMessage({
    id: 'form.phoneNumber',
    defaultMessage: 'Phone number is not valid',
  });

  const schema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().trim().required(requiredError),
      lastName: yup.string().trim().required(requiredError),
      addressType: yup.string().trim().required(requiredError),
      streetName: yup.string().trim().required(requiredError),
      postalCode: yup
        .string()
        .required(requiredError)
        .matches(/^\d{2}(-)?\d{3}?$/, postalCodeError),
      city: yup.string().trim().required(requiredError),
      phone: yup
        .string()
        .matches(/^\d{9}$/, { message: phoneNumberError, excludeEmptyString: true })
        .required(requiredError),
    });
  }, [requiredError, postalCodeError, phoneNumberError]);

  const { formValidation, fieldValidation } = useYupValidation();

  const validateFirstName = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'firstName', value);
    },
    [fieldValidation, schema, data],
  );

  const validateLastName = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'lastName', value);
    },
    [fieldValidation, schema, data],
  );

  const validateStreetName = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'streetName', value);
    },
    [fieldValidation, schema, data],
  );

  const validatePostalCode = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'postalCode', value);
    },
    [fieldValidation, schema, data],
  );

  const validateCity = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'city', value);
    },
    [fieldValidation, schema, data],
  );

  const validatePhone = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'phone', value);
    },
    [fieldValidation, schema, data],
  );

  const fields = useCallback(() => {
    return [
      {
        name: 'firstName',
        label: formatMessage({ id: 'firstName', defaultMessage: 'First Name' }),
        labelDesc: '',
        required: true,
        type: 'string',
        className: 'col-span-4 lg:col-span-2',
        validationSchema: validateFirstName,
      },
      {
        name: 'lastName',
        label: formatMessage({ id: 'lastName', defaultMessage: 'Last Name' }),
        labelDesc: '',
        required: true,
        type: 'string',
        className: 'col-span-4 lg:col-span-2',
        validationSchema: validateLastName,
      },
      {
        name: 'company',
        label: formatMessage({ id: 'companyName', defaultMessage: 'Company name' }),
        labelDesc: '',
        required: false,
        type: 'string',
        className: 'col-span-4',
      },
      {
        name: 'streetName',
        label: formatMessage({ id: 'address', defaultMessage: 'Address' }),
        labelDesc: '',
        required: true,
        type: 'string',
        className: 'col-span-4 lg:col-span-3',
        validationSchema: validateStreetName,
      },
      {
        name: 'streetNumber',
        label: formatMessage({ id: 'street.number', defaultMessage: 'Number' }),
        labelDesc: '',
        required: false,
        type: 'string',
        className: 'col-span-4 lg:col-span-1',
      },
      {
        name: 'postalCode',
        label: formatMessage({ id: 'zipCode', defaultMessage: 'Postcode' }),
        labelDesc: '',
        required: true,
        className: 'col-span-4 lg:col-span-2 mt-12',
        validationSchema: validatePostalCode,
      },
      {
        name: 'city',
        label: formatMessage({ id: 'city', defaultMessage: 'City' }),
        labelDesc: '',
        required: true,
        className: 'col-span-4 lg:col-span-2 mt-12',
        validationSchema: validateCity,
      },
      {
        name: 'phone',
        label: formatMessage({ id: 'phone', defaultMessage: 'Phone' }),
        labelDesc: '',
        required: true,
        className: 'col-span-4',
        placeholder: '999999999',
        tooltip: formatMessage({
          id: 'phone.info.shipment',
          defaultMessage: 'The phone number will help deliver your shipment more efficiently.',
        }),
        validationSchema: validatePhone,
      },
    ] as AddressFormFields[];
  }, [
    formatMessage,
    validateCity,
    validateFirstName,
    validateLastName,
    validateStreetName,
    validatePhone,
    validatePostalCode,
  ]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newData = { ...data, [e.target.name]: e.target.value };
      setData(newData);
      const isValid = formValidation(schema, newData);
      setIsValidForm(isValid);
    },
    [formValidation, schema, data],
  );

  const handleSubmit = useCallback(async () => {
    startProcessing();
    try {
      const { addAddress, updateAddress } = mapPropsToAddress({ ...data, isDefaultAddress: saveAsDefault });

      if (editedAddressId) {
        await updateAddress();
      } else {
        await addAddress();
      }
    } finally {
      stopProcessing();
      closeModal();
      discardForm();
      setIsValidForm(false);
      setSaveAsDefault(false);
    }
  }, [data, discardForm, editedAddressId, mapPropsToAddress, saveAsDefault, startProcessing, stopProcessing]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);

    try {
      await removeAddress(data.addressId);
    } finally {
      setLoadingDelete(false);
      closeModal();
      discardForm();
    }
  }, [data.addressId, discardForm, removeAddress]);

  return (
    <>
      <AddressFormFields
        className="mt-24"
        address={data}
        fields={fields}
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        <div className="mt-24">
          {data.isDefaultShippingAddress ? (
            <div className="flex items-center gap-4">
              <div className="h-24 w-24">
                <CheckmarkIcon scale={1} />
              </div>
              <Typography className="text-16 leading-[20px] text-neutral-4">
                {formatAccountMessage({ id: 'default', defaultMessage: 'Default delivery address' })}
              </Typography>
            </div>
          ) : (
            <Checkbox
              label={formatAccountMessage({
                id: 'address.setDefault',
                defaultMessage: 'Save as default address',
              })}
              labelPosition="on-right"
              checked={saveAsDefault}
              onChange={({ checked }) => {
                setSaveAsDefault(checked);
                setIsValidForm(formValidation(schema, data));
              }}
            />
          )}
        </div>
        <div className="mt-32 flex flex-col gap-12 lg:flex-row">
          <Button
            variant="primary"
            className={`px-48 ${editedAddressId ? 'lg:basis-1/3' : 'lg:basis-1/2'}`}
            type="submit"
            loading={processing}
            disabled={!isValidForm}
          >
            {formatMessage({ id: 'save', defaultMessage: 'Save' })}
          </Button>
          <Button
            variant="secondary"
            className={`px-48 ${editedAddressId ? 'lg:basis-1/3' : 'lg:basis-1/2'}`}
            type="button"
            onClick={discardForm}
          >
            {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
          </Button>
          {editedAddressId && (
            <div
              className="flex items-center gap-4 text-neutral-4 hover:cursor-pointer max-lg:mt-12 lg:basis-1/3 lg:justify-end"
              onClick={() => setModalIsOpen(true)}
            >
              <TrashIcon />
              <Typography className="text-16 leading-[20px]" as="span">
                {formatAccountMessage({ id: 'delete.address', defaultMessage: 'Delete address' })}
              </Typography>
            </div>
          )}
        </div>
        <DeleteModal
          modalIsOpen={modalIsOpen}
          loading={loadingDelete}
          closeModal={closeModal}
          handleDelete={handleDelete}
        />
      </AddressFormFields>
    </>
  );
};

export default AddressForm;
