function BuildingIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25 * scale} height={24 * scale} fill="none" viewBox="0 0 25 24">
      <path
        fill="currentColor"
        d="M3.75 20.578v-13.5h4v-4h9.5v8h4v9.5h-7.5v-4h-2.5v4h-7.5zm1.5-1.5h2.5v-2.5h-2.5v2.5zm0-4h2.5v-2.5h-2.5v2.5zm0-4h2.5v-2.5h-2.5v2.5zm4 4h2.5v-2.5h-2.5v2.5zm0-4h2.5v-2.5h-2.5v2.5zm0-4h2.5v-2.5h-2.5v2.5zm4 8h2.5v-2.5h-2.5v2.5zm0-4h2.5v-2.5h-2.5v2.5zm0-4h2.5v-2.5h-2.5v2.5zm4 12h2.5v-2.5h-2.5v2.5zm0-4h2.5v-2.5h-2.5v2.5z"
      ></path>
    </svg>
  );
}

export default BuildingIcon;
