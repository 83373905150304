import React, { FC } from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
  orderNumber: string;
}

const OrderNumber: FC<Props> = ({ orderNumber }) => {
  const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

  return (
    <div className="flex flex-col gap-y-8 bg-background px-20 py-32 lg:bg-neutral-5">
      <div>
        <Typography as="h2" className="text-18 text-neutral-4 lg:text-24">
          {formatOrdersMessage({
            id: 'order.details',
            defaultMessage: 'Order Details',
          })}
        </Typography>
      </div>

      <div className="flex flex-row">
        <Typography className="whitespace-nowrap text-14 text-neutral-4 md:text-16">
          {formatOrdersMessage({
            id: 'order.number',
            defaultMessage: 'Order number:',
          })}
        </Typography>
        <Typography className="whitespace-nowrap text-14 font-medium text-neutral-4 md:text-16">
          {orderNumber.replaceAll('-', ' ')}
        </Typography>
      </div>
    </div>
  );
};

export default OrderNumber;
