import React, { useCallback, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import ChevronDownIcon from 'components/icons/ChevronDownIcon';
import ExclamationMarkIcon from 'components/icons/ExclamationMarkIcon';
import { Option } from './index';
import Typography from '../typography';

export interface Props {
  isValid?: boolean;
  label?: string;
  required?: boolean;
  labelClassName?: string;
  options?: Option[];
  defaultValue?: Option;
  onChange?: (option: Option) => void;
  selectButtonClassName?: string;
  errorMessage?: string;
}

const Select: React.FC<Props> = ({
  isValid = true,
  onChange,
  defaultValue,
  label,
  required,
  labelClassName = '',
  selectButtonClassName,
  options = [],
  errorMessage,
}) => {
  const [selected, setSelected] = useState<Option | undefined>(defaultValue ?? options?.[0]);

  useEffect(() => {
    setSelected(defaultValue ?? options?.[0]);
  }, [defaultValue, options]);

  const handleChange = useCallback(
    (option: Option) => {
      setSelected(option);
      onChange?.(option);
    },
    [onChange],
  );

  const buttonClassNames = useCallback(() => {
    return `${!isValid ? 'border-status-danger-border' : 'border-neutral-2'}
        cursor-pointer relative flex h-[48px] cursor-default items-center border px-20 py-8 rounded-full
        w-full bg-neutral-5 focus:border-neutral-2 active:border-neutral-2
        ${selectButtonClassName}`;
  }, [selectButtonClassName, isValid]);

  return (
    <>
      {label && (
        <div className="mb-8">
          <Typography as="label" className={`${labelClassName} text-16 font-medium text-neutral-4`}>
            {required ? `${label} *` : label}
          </Typography>
        </div>
      )}
      <Listbox value={selected} onChange={handleChange}>
        {() => (
          <div className="relative w-full">
            <Listbox.Button className={buttonClassNames()}>
              <span className="text-16 leading-5">{selected?.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-17 text-neutral-4">
                <ChevronDownIcon />
              </span>
            </Listbox.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform origin-top scale-y-0"
              enterTo="transform origin-top scale-y-100"
              leave="transition ease-in duration-100"
              leaveFrom="transform origin-top scale-y-150"
              leaveTo="transform origin-top scale-y-0"
            >
              <Listbox.Options className="no-scrollbar absolute top-39 z-50 mt-16 max-h-172 w-full overflow-y-scroll rounded-[20px] border border-neutral-2 bg-neutral-5 py-16">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      `relative cursor-pointer select-none px-20 py-12 text-16 leading-5 hover:bg-base-accent-3 ${
                        active ? 'bg-neutral-200' : ''
                      }`
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate text-16 leading-5 ${selected ? 'font-medium' : 'font-normal'}`}
                        >
                          {option.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-8">
                            <CheckmarkIcon scale={0.45} />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
      {!isValid && errorMessage && (
        <div className="mt-12 flex items-center gap-4">
          <span className="text-status-danger-border">
            <ExclamationMarkIcon />
          </span>
          <Typography className="text-14 text-status-danger-border" as="span">
            {errorMessage}
          </Typography>
        </div>
      )}
    </>
  );
};

export default Select;
