import React, { useCallback, useState } from 'react';
import { Address } from 'shared/types/account/Address';
import Modal from 'components/commercetools-ui/atoms/modal';
import CloseIcon from 'components/icons/CloseIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import Addresses from '.';
import AddressForm from './address-form';
import useDiscardForm from '../../hooks/useDiscardForm';

export type AddressModalProps = {
  editedAddressId?: Address['addressId'];
};

const AddressModal = ({ editedAddressId }: AddressModalProps) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const { discardForm } = useDiscardForm();

  const { loggedIn } = useAccount();

  const [isOpen, setIsOpen] = useState(true);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    discardForm();
  }, [discardForm]);

  if (!loggedIn) return <></>;

  return (
    <>
      <Addresses />
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={{ content: { background: 'transparent', border: 'none', maxHeight: '95vh', overflowY: 'auto' } }}
        closeTimeoutMS={200}
      >
        <div className="shadow-custom relative mx-auto w-[90%] max-w-[800px] bg-white px-12 py-32 lg:w-full lg:px-32">
          <div className="absolute right-15 top-15 hover:cursor-pointer lg:right-20 lg:top-20" onClick={closeModal}>
            <CloseIcon />
          </div>
          <p className="text-center text-28 font-bold leading-[35px] text-neutral-4">
            {editedAddressId
              ? formatAccountMessage({ id: 'address.edit', defaultMessage: 'Edit address' })
              : formatAccountMessage({ id: 'address.add', defaultMessage: 'Add new address' })}
          </p>
          <AddressForm editedAddressId={editedAddressId} />
        </div>
      </Modal>
    </>
  );
};

export default AddressModal;
