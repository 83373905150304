import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Order } from 'shared/types/cart/Order';
import AccordionBtn from 'components/commercetools-ui/atoms/accordion';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import useOrderFetch from './helper-hooks/useOrderFetch';
import OrderItem from './OrderItem';
import OrderStatusesTable from './OrderStatusesTable';

export interface StatusTab {
  name: string;
  slug: string;
}

export type OrderStatus = {
  status: string;
  description: string;
};

export interface OrdersProps {
  orderStatuses?: OrderStatus[];
  modificationOfOrders?: string;
}

const Orders: React.FC<OrdersProps> = ({ orderStatuses, modificationOfOrders }) => {
  const { orders, loading } = useOrderFetch();
  const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //Load more orders
  const [visibleOrders, setVisibleOrders] = useState<Order[]>([]);
  const [visibleOrdersCount, setVisibleOrdersCount] = useState(24);
  useEffect(() => {
    if (orders) {
      setVisibleOrders(orders.slice(0, visibleOrdersCount));
    }
  }, [orders, visibleOrdersCount]);

  const loadMoreOrders = () => {
    setVisibleOrdersCount(visibleOrdersCount + 24);
  };

  return (
    <>
      <div className="hidden gap-12 px-20 pt-32 lg:flex lg:flex-col">
        <Typography as="h2" className="leading-[30px] text-neutral-4 md:!text-24">
          {formatOrdersMessage({
            id: 'orders',
            defaultMessage: 'Order history',
          })}
        </Typography>
        <p className="text-16 leading-6">
          {formatAccountMessage({
            id: 'order.sidebar.description',
            defaultMessage: 'Check status of recent orders, manage your returns and download invoices.',
          })}
        </p>
      </div>
      {loading ? (
        <div className="px-12">
          <Skeleton className="h-[30px]" />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-24 overflow-auto px-20 pb-32 md:pt-24">
            {visibleOrders?.map((order?: Order) => (
              <OrderItem key={order?.orderId} order={order} />
            ))}

            {orders?.length > visibleOrdersCount && (
              <div className="flex flex-col items-center justify-center gap-20">
                <p className="flex gap-x-4">
                  <span> {formatOrdersMessage({ id: 'show', defaultMessage: 'Show' })} </span>
                  <span> {visibleOrdersCount > orders.length ? orders.length : visibleOrdersCount} </span>
                  <span>
                    {formatOrdersMessage({ id: 'of', defaultMessage: 'of' })} {orders?.length}{' '}
                  </span>
                </p>
                <Button
                  data-test="load-more-button"
                  className="mx-auto block"
                  variant="secondary"
                  disabled={visibleOrdersCount >= orders?.length}
                  onClick={loadMoreOrders}
                >
                  {formatOrdersMessage({ id: 'loadMore', defaultMessage: 'Load More' })}
                </Button>
              </div>
            )}
            {orderStatuses && orderStatuses.length >= 1 && (
              <AccordionBtn
                className="col-span-2 md:mt-0"
                closedSectionTitle={formatOrdersMessage({
                  id: 'orderStatuses',
                  defaultMessage: 'Order statuses',
                })}
                buttonClassName="pb-12 pt-20 font-bold border-b border-neutral-2 text-16 leading-tight text-neutral-4 pl-0 md:pr-12 mb-8 md:[&>p]:pl-12"
                panelClassName="md:pt-10 text-neutral-4 md:px-12"
              >
                <OrderStatusesTable orderStatuses={orderStatuses} />
              </AccordionBtn>
            )}

            {modificationOfOrders && (
              <AccordionBtn
                className="col-span-2 md:mt-0"
                closedSectionTitle={formatOrdersMessage({
                  id: 'modificationOfOrders',
                  defaultMessage: 'Modification of orders',
                })}
                buttonClassName="pb-12 pt-20 font-bold border-b border-neutral-2 text-16 leading-tight text-neutral-4 pl-0 md:pr-12 mb-8 md:[&>p]:pl-12"
                panelClassName="md:pt-10 text-neutral-4 md:px-12"
              >
                <Typography className="px-20 text-16 leading-6 md:text-14 md:leading-[21px]">
                  {modificationOfOrders}
                </Typography>
              </AccordionBtn>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Orders;
