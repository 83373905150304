function HomeIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M6 19h3.346v-5.942h5.308V19H18v-9l-6-4.52L6 10v9zm-1.5 1.5V9.25L12 3.606l7.5 5.644V20.5h-6.346v-5.943h-2.308V20.5H4.5z"
      ></path>
    </svg>
  );
}

export default HomeIcon;
