import { FC, ReactNode } from 'react';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import InfoIcon from 'components/icons/InfoIcon';
import TruckIcon from 'components/icons/TruckIcon';

export type InfoMessageIcon = 'info' | 'warning' | 'successful' | 'error';
export interface InfoMessageProps {
  headline?: string;
  text?: string;
  label?: string;
  icon?: string;
  additionalClassName?: string;
  iconColor?: InfoMessageIcon;
  button?: ReactNode;
  errorMessage?: string;
  textDescriptionClassName?: string;
}

const getIcon = (icon: string) => {
  switch (icon) {
    case 'Truck':
      return <TruckIcon />;
    case 'Checkmark':
      return <CheckmarkIcon />;
    default:
      return <InfoIcon />;
  }
};

const getIconColor = (iconColor: string) => {
  switch (iconColor) {
    case 'warning':
      return 'border-status-warning-border text-status-warning-border';
    case 'successful':
      return 'border-status-success-border text-status-success-border';
    case 'error':
      return 'border-status-danger-border text-status-danger-border';
    default:
      return 'border-base-accent-2 text-base-accent-1';
  }
};

const InfoMessage: FC<InfoMessageProps> = ({
  headline,
  text,
  label,
  icon,
  additionalClassName,
  iconColor = 'info',
  button,
  errorMessage,
  textDescriptionClassName,
}) => {
  const onlyHeadline = headline && !text;
  const iconStyle = getIconColor(iconColor);
  return (
    <div className={`shadow-custom flex w-full flex-col gap-10 bg-base-accent-3 p-20 sm:px-40 ${additionalClassName}`}>
      <div
        className={`flex content-start ${onlyHeadline ? 'items-center' : 'items-start'} gap-x-20 gap-y-10 self-stretch`}
      >
        {/* Headline and text */}
        <div className="flex grow flex-col items-start gap-8">
          {headline ? <p className="text-16 font-bold leading-[25px] text-neutral-4 sm:text-20">{headline}</p> : null}
          {text ? (
            <p className={`text-12 leading-[17.5px] text-neutral-4 sm:text-14 ${textDescriptionClassName}`}>{text}</p>
          ) : null}
          {button ? <div className="mt-12 flex">{button}</div> : null}
          {button && errorMessage && (
            <div className="mt-8 text-red-500">
              <p className="text-12 font-bold">{errorMessage}</p>
            </div>
          )}
        </div>
        {/* Label and icon */}
        <div className="flex shrink-0 items-center gap-10">
          {label ? <p className="text-14 leading-[17.5px]">{label}</p> : null}
          {icon ? (
            <div
              className={`flex h-40 w-40 items-center justify-center rounded-[51px] border bg-neutral-5 p-8 ${iconStyle}`}
            >
              {getIcon(icon)}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default InfoMessage;
