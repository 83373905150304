import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import Address from './address';

const Addresses: React.FC = () => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const { account, isLoading } = useAccount();
  const addresses = account?.addresses;

  return (
    <div className="px-20 pb-32 md:py-32">
      <div className="flex flex-col gap-20 lg:flex-row lg:items-end lg:justify-between">
        <div className="hidden text-neutral-4 lg:flex lg:flex-col lg:gap-12">
          <Typography as="h2" className="text-24 font-bold">
            {formatAccountMessage({
              id: 'addresses',
              defaultMessage: 'Addresses',
            })}
          </Typography>
          <Typography>
            {formatAccountMessage({
              id: 'address.desc',
              defaultMessage: 'Manage or add addresses for your account.',
            })}
          </Typography>
        </div>
        {isLoading ? null : (
          <div>
            <Link link="?hash=addresses&id=address-add">
              <Button variant="secondary" className="w-full">
                {formatAccountMessage({ id: 'address.add', defaultMessage: 'Add an address' })}
              </Button>
            </Link>
          </div>
        )}
      </div>
      {isLoading ? (
        <div className="mt-20 md:mt-24">
          <Skeleton className="h-[30px]" />
        </div>
      ) : (
        <div className="mt-20 flex flex-col gap-12 md:mt-24">
          {addresses?.map((address) => (
            <Address key={address.addressId} address={address} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Addresses;
