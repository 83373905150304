import React, { FC } from 'react';
import { Order } from 'shared/types/cart/Order';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';

export interface Props {
  order: Order;
  shippingInfo: string;
  paymentInfo: string;
  shippingAddress: string;
}

const OrderInfoSection: FC<Props> = ({ order, shippingInfo, paymentInfo, shippingAddress }) => {
  const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

  const name = `${order.shippingAddress?.firstName ?? ''} ${order.shippingAddress?.lastName ?? ''}`;

  return (
    <div className="grid h-fit w-full gap-20 bg-base-accent-3 px-12 py-32 md:px-20">
      <div className="flex">
        <Typography className="min-w-[165px] whitespace-nowrap text-16 leading-6 lg:min-w-[218px]">
          {formatOrdersMessage({
            id: 'shipping.method',
            defaultMessage: 'Shipping method:',
          })}
        </Typography>

        <Typography className="text-16 leading-6 text-neutral-4">{shippingInfo}</Typography>
      </div>

      <div className="flex">
        <Typography className="min-w-[165px] text-16  leading-6 lg:min-w-[218px]">
          {formatOrdersMessage({
            id: 'shipping.address',
            defaultMessage: 'Shipping address:',
          })}
        </Typography>

        <div className="">
          <Typography className="text-16 leading-6 text-neutral-4">{name}</Typography>
          {shippingAddress &&
            shippingAddress.split(',').map((line) => (
              <Typography key={line} className="text-16 leading-6 text-neutral-4">
                {line}
              </Typography>
            ))}
        </div>
      </div>

      <div className="flex">
        <Typography className="min-w-[165px] text-16 leading-6 lg:min-w-[218px]">
          {formatOrdersMessage({
            id: 'payment.method',
            defaultMessage: 'Payment method:',
          })}
        </Typography>

        <Typography className="text-16 leading-6 text-neutral-4">{paymentInfo}</Typography>
      </div>
    </div>
  );
};

export default OrderInfoSection;
