function CreditCardIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M21.5 6.308v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H4.308c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283zM4 8.404h16V6.308a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H4.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v2.096zm0 3.192v6.096c0 .077.032.148.096.212a.294.294 0 00.212.096h15.384a.294.294 0 00.212-.096.294.294 0 00.096-.212v-6.096H4z"
      ></path>
    </svg>
  );
}

export default CreditCardIcon;
