import React, { useCallback, useState } from 'react';
import type { Address as AddressType } from 'shared/types/account/Address';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import PencilIcon from 'components/icons/PencilIcon';
import TrashIcon from 'components/icons/TrashIcon';
import TruckIcon from 'components/icons/TruckIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import { AddressFormData } from './address-form';
import DeleteModal from './deleteModal';
import usePropsToAddressType from './mapPropsToAddressType';

export interface AddressProps {
  address: AddressType;
}

const Address: React.FC<AddressProps> = ({ address }) => {
  const { removeAddress } = useAccount();
  const { mapPropsToAddress } = usePropsToAddressType();
  const { label } = mapPropsToAddress(address as AddressFormData);

  const { formatMessage } = useFormat({ name: 'account' });

  const addressInfoTypographyElements = [
    `${address.firstName ?? ''} ${address.lastName ?? ''}`,
    `${address.company ?? ''}`,
    `${address.streetName ?? ''} ${address.streetNumber ?? ''}`,
    `${address.postalCode ?? ''} ${address.city ?? ''}`,
    `${address.phone ?? ''}`,
  ];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleDelete = useCallback(async () => {
    if (!address.addressId) {
      return;
    }

    setLoadingDelete(true);

    try {
      await removeAddress(address.addressId);
    } finally {
      setLoadingDelete(false);
      closeModal();
    }
  }, [address.addressId, removeAddress]);

  return (
    <div className="border border-neutral-2 bg-neutral-5" key={address.addressId}>
      <div className="flex justify-between gap-28 px-20 py-24">
        <div className="flex flex-col gap-12 lg:gap-24">
          <div className="flex items-center gap-8">
            <div className="h-24 w-24 text-base-accent-1">
              <TruckIcon scale={1} />
            </div>
            <Typography className="text-16 font-bold leading-[20px] text-neutral-4">{label}</Typography>
          </div>

          <div className="grid">
            {addressInfoTypographyElements.map((element, id) => (
              <Typography key={id} className="text-16 leading-[24px] text-neutral-4">
                {element}
              </Typography>
            ))}
          </div>
          {address.isDefaultShippingAddress && (
            <div className="flex items-center gap-4">
              <div className="h-24 w-24">
                <CheckmarkIcon scale={1} />
              </div>
              <Typography className="text-16 font-bold leading-[20px] text-neutral-4">
                {formatMessage({ id: 'default', defaultMessage: 'Default delivery address' })}
              </Typography>
            </div>
          )}
        </div>
        {address.isBillingAddress ? null : (
          <div className="flex flex-col items-center justify-between">
            <Link link={`?hash=addresses&id=address_${address.addressId}`}>
              <PencilIcon />
            </Link>

            <span
              className="text-14 text-secondary-black underline decoration-secondary-black underline-offset-2 hover:cursor-pointer"
              onClick={() => setModalIsOpen(true)}
            >
              <TrashIcon />
            </span>
          </div>
        )}
      </div>
      <DeleteModal
        modalIsOpen={modalIsOpen}
        loading={loadingDelete}
        closeModal={closeModal}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default Address;
