function ClipboardIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M5.308 20.5c-.499 0-.925-.177-1.278-.53a1.741 1.741 0 01-.53-1.278V5.308c0-.5.177-.925.53-1.278.353-.354.779-.53 1.278-.53h4.405c.139-.487.422-.891.85-1.212.427-.32.906-.48 1.437-.48.551 0 1.038.16 1.46.48.423.32.703.725.842 1.212h4.39c.499 0 .925.176 1.278.53.353.353.53.779.53 1.278v13.384c0 .499-.177.925-.53 1.278-.353.353-.779.53-1.278.53H5.308zm0-1.5h13.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V5.308a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H16.5v2.615h-9V5H5.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v13.384c0 .077.032.148.096.212a.294.294 0 00.212.096zM12 5.115a.876.876 0 00.645-.258.876.876 0 00.259-.646.876.876 0 00-.259-.645.876.876 0 00-.645-.258.876.876 0 00-.645.258.876.876 0 00-.259.645c0 .258.086.473.259.646a.876.876 0 00.645.258z"
      ></path>
    </svg>
  );
}

export default ClipboardIcon;
