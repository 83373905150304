import { FC } from 'react';
import { Account } from 'shared/types/account';
import CreditCardIcon from 'components/icons/CreditCardIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import InfoCard from '../../../account-atoms/info-card';

interface Props {
  account: Account;
}

const AccountCard: FC<Props> = ({ account }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const header = {
    icon: <CreditCardIcon />,
    title: formatAccountMessage({
      id: 'accountCard.title',
      defaultMessage: 'Account information',
    }),
  };

  const fields: Array<{ label: string; value: string }> = [
    {
      label: formatAccountMessage({ id: 'accountCard.clientNumber', defaultMessage: 'Client number' }),
      value: account.clientNumber ?? '-',
    },
    {
      label: formatAccountMessage({ id: 'accountCard.cardNumber', defaultMessage: 'Card number' }),
      value: account.cardNumber ?? '-',
    },
  ];

  return (
    <InfoCard header={header}>
      <div className="flex flex-col gap-16">
        {fields.map((field, i) => {
          return (
            <div key={i} className="flex flex-col gap-8 text-neutral-4">
              <p className="font-bold">{field.label}</p>
              <p>{field.value}</p>
            </div>
          );
        })}
      </div>
    </InfoCard>
  );
};

export default AccountCard;
