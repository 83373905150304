import React, { FC } from 'react';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import Link from 'components/commercetools-ui/atoms/link';
import InfoMessage from 'components/commercetools-ui/organisms/info-message';
import OrderSummary from 'components/commercetools-ui/organisms/order-summary';
import useOrderInfoData from 'components/commercetools-ui/organisms/thank-you/hooks/useOrderInfoData';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { useP24Transaction } from 'frontastic/hooks/useP24Transaction';
import OrderDescription from './order-description';
import OrderInfoSection from './order-info';
import OrderNumber from './order-number';
import { OrderHistory } from '../../..';
import useOrderFetch from '../helper-hooks/useOrderFetch';

export interface Props {
  orderId?: string;
  studioData?: OrderHistory[];
}

const OrderPage: FC<Props> = ({ orderId, studioData }) => {
  const [isDesktopSize] = useMediaQuery(desktop);
  const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

  const { orders } = useOrderFetch();
  const order = orders.find((order) => order.orderId === orderId);
  const data = studioData?.[0];
  const { paymentStatusOpenHeadline, paymentStatusOpenDescription, paymentStatusPaidHeadline, payNowButtonLabel } =
    data ?? {};

  const { deliveryMethod, shippingAddress, paymentInfo } = useOrderInfoData(order);
  const { urlReturn, error, loading } = useP24Transaction(orderId ?? '');

  return (
    <div className="flex flex-col bg-background lg:gap-20">
      {order && (
        <>
          <OrderNumber orderNumber={order.orderId ?? ''} />

          <div className="flex flex-col gap-12 lg:flex-row">
            {/* Payment Status and Details of Order */}
            <div className="flex flex-col lg:w-2/4">
              <div className="flex flex-col gap-12 max-sm:mx-12 lg:gap-20">
                {data && order.paymentMethod === 'P24' ? (
                  order.orderState === 'Open' ? (
                    <InfoMessage
                      headline={paymentStatusOpenHeadline}
                      text={paymentStatusOpenDescription}
                      icon="ExclamationMarkIcon"
                      additionalClassName="bg-status-warning-bg text-16 leading-5 !px-12 !py-20 md:!p-20 !shadow-none"
                      textDescriptionClassName="!text-16 !leading-5"
                      iconColor="warning"
                      button={
                        <Link variant="button-primary" link={urlReturn}>
                          {loading ? (
                            <span className="flex justify-center [&>span>svg]:hidden">
                              <LoadingIcon className={'fill-white'} />
                            </span>
                          ) : (
                            payNowButtonLabel ?? 'Pay now'
                          )}
                        </Link>
                      }
                      errorMessage={
                        error
                          ? formatOrdersMessage({
                              id: 'errorTransaction',
                              defaultMessage: 'Sorry, an error occurred. Try again later.',
                            })
                          : undefined
                      }
                    />
                  ) : (
                    <InfoMessage
                      headline={paymentStatusPaidHeadline}
                      icon="Checkmark"
                      additionalClassName="bg-status-success-bg text-16 leading-5 !px-12 !py-20 md:!p-20 !shadow-none"
                      iconColor="successful"
                    />
                  )
                ) : null}
                <OrderInfoSection
                  order={order}
                  shippingInfo={deliveryMethod}
                  paymentInfo={paymentInfo ?? ''}
                  shippingAddress={shippingAddress}
                />
              </div>
              {/* Order Description Desktop */}
              {isDesktopSize && data && <OrderDescription studioData={data} />}
            </div>

            {/* Order Summary */}
            <OrderSummary order={order} wrapperAdditionalClassName="h-full flex-1" />
          </div>

          {!isDesktopSize && (
            <div className="mt-24">
              {/* Order Description Mobile */}
              {data && <OrderDescription studioData={data} />}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OrderPage;
