import { FC } from 'react';
import { Account } from 'shared/types/account';
import BuildingIcon from 'components/icons/BuildingIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import InfoCard from '../../../account-atoms/info-card';

interface Props {
  account: Account;
}

const CompanyCard: FC<Props> = ({ account }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const header = {
    icon: <BuildingIcon />,
    title: formatAccountMessage({
      id: 'companyCard.title',
      defaultMessage: 'Company information',
    }),
  };

  const fields: Array<{ label: string; value: string }> = [
    { label: formatAccountMessage({ id: 'companyCard.nip', defaultMessage: 'NIP' }), value: account?.nipId ?? '-' },
    {
      label: formatAccountMessage({ id: 'companyCard.companyName', defaultMessage: 'Company name' }),
      value: account?.companyName ?? '-',
    },
    {
      label: formatMessage({ id: 'streetNameAndNumber', defaultMessage: 'Street name and number' }),
      value: `${account?.addresses?.[0]?.streetName ?? ''} ${account?.addresses?.[0]?.streetNumber ?? ''}`,
    },
    {
      label: formatMessage({ id: 'zipCode', defaultMessage: 'Zipcode' }),
      value: account?.addresses?.[0]?.postalCode ?? '-',
    },
    { label: formatMessage({ id: 'city', defaultMessage: 'City' }), value: account?.addresses?.[0]?.city ?? '-' },
  ];

  return (
    <InfoCard header={header}>
      <div className="flex flex-col gap-16">
        {fields.map((field, i) => {
          return (
            <div key={i} className="flex flex-col gap-8 text-neutral-4">
              <p className="font-bold">{field.label}</p>
              <p>{field.value}</p>
            </div>
          );
        })}
      </div>
    </InfoCard>
  );
};

export default CompanyCard;
