import React, { FC } from 'react';
import { useParams } from 'next/navigation';
import Skeleton from 'react-loading-skeleton';
import { Order } from 'shared/types/cart/Order';
import Typography from 'components/commercetools-ui/atoms/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import OrderItemsListing from '../order-items-listing';
import useCostsData from '../order-payment-section/hooks/useCostsData';

type OrderSummaryProps = {
  order?: Order;
  wrapperAdditionalClassName?: string;
};

// Used in "Thank You" and "Account > Orders > Order Details" pages
const OrderSummary: FC<OrderSummaryProps> = ({ order, wrapperAdditionalClassName = '' }) => {
  const { formatMessage } = useFormat({ name: 'thank-you' });
  const { locale } = useParams();
  const { loading, costsToRender, total } = useCostsData({ dataReference: 'order', order });

  return (
    <div
      className={
        `shadow-custom relative bg-white px-16 pb-24 md:px-24 lg:mt-0 lg:block lg:w-[388px] lg:px-20 lg:py-32 ` +
        wrapperAdditionalClassName
      }
    >
      {/* Title */}
      <div className="relative pt-16 md:pt-24 lg:pt-0">
        <Typography asSkeleton={!order} className="text-20 font-bold text-neutral-4">
          {formatMessage({ id: 'order.details', defaultMessage: 'Order details' })}
        </Typography>
      </div>

      {/* Items list */}
      {!order && (
        <div className="mt-24 flex gap-12">
          <Skeleton className="h-78 w-78" />
          <Skeleton className="h-78 w-78" />
        </div>
      )}
      {order && order.lineItems && <OrderItemsListing lineItems={order.lineItems} />}

      {/* Costs info */}
      <div className="grid gap-24 py-16 lg:pt-32">
        {!order && (
          <>
            <Skeleton className="h-24 w-full" />
            <Skeleton className="h-24 w-full" />
          </>
        )}
        {order &&
          costsToRender.map(({ key, label, value }) =>
            value?.centAmount && value.centAmount > 0 ? (
              <div key={key} className="flex items-center justify-between text-neutral-4">
                <Typography asSkeleton={loading}>{label}</Typography>
                <Typography asSkeleton={loading}>{CurrencyHelpers.formatForCurrency(value, locale)}</Typography>
              </div>
            ) : null,
          )}
      </div>

      {/* Total info */}
      <div className="mt-24 flex items-center justify-between border-t border-neutral-4 pt-16 text-neutral-4">
        <Typography asSkeleton={!order}>{total.label}</Typography>
        <Typography asSkeleton={!order} className="font-bold md:text-18">
          {CurrencyHelpers.formatForCurrency(total.value, locale)}
        </Typography>
      </div>
    </div>
  );
};

export default OrderSummary;
