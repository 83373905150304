import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import { InputProps } from 'components/commercetools-ui/atoms/input';
import PasswordInput from 'components/commercetools-ui/atoms/input-password';
import Typography from 'components/commercetools-ui/atoms/typography';
import ChangePasswordSuccessIcon from 'components/icons/ChangePasswordSuccessIcon';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { useAccount } from 'frontastic';

type ChangePasswordFormData = {
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

type Props = {
  closeModal: () => void;
};

const ChangePasswordForm = ({ closeModal }: Props) => {
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { changePassword } = useAccount();

  const defaultData: ChangePasswordFormData = { password: '', newPassword: '', newPasswordConfirmation: '' };
  const [data, setData] = useState<ChangePasswordFormData>(defaultData);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorCurrentPasswordInvalid, setErrorCurrentPasswordInvalid] = useState(false);
  const [errorPasswordChangeGeneric, setErrorPasswordChangeGeneric] = useState(false);
  const { fieldValidation, formValidation } = useYupValidation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setErrorCurrentPasswordInvalid(false);
    setErrorPasswordChangeGeneric(false);

    try {
      const account = await changePassword(data.password, data.newPassword);
      if (account.accountId) {
        setShowSuccess(true);
      } else {
        console.error('Request successful but `account` does not contain `accountId`...');
      }
    } catch (err) {
      if (err === 400) {
        setErrorCurrentPasswordInvalid(true);
      } else {
        setErrorPasswordChangeGeneric(true);
      }

      //Scroll to the top of the modal to see the error message
      document.getElementById('change-password-modal')?.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setLoading(false);
    }
  };

  const fieldIsRequiredMessage = formatErrorMessage({
    id: 'form.required',
    defaultMessage: 'The field is required',
  });
  const currentPasswordIsWrongMessage = formatErrorMessage({
    id: 'password.current.wrong',
    defaultMessage: `Incorrect password. Try again. If you don't remember your current password, you can reset your password on the login page.`,
  });
  const errorPasswordChangeGenericMessage = formatErrorMessage({
    id: 'password.change.failed',
    defaultMessage: `There was an error while changing your password. If this happens multiple times you can also reset your password on the login page.`,
  });
  const newPasswordIsInvalidMessage = formatErrorMessage({
    id: 'password.not.valid',
    defaultMessage: 'Password has to be at least 8 characters long and have at least one uppercase letter.',
  });
  const newPasswordIsEqualToCurrentMessage = formatErrorMessage({
    id: 'password.not.valid.equal',
    defaultMessage: 'New password is equal to the current password. Please choose a different password.',
  });
  const newPasswordConfirmationDoesntMatchMessage = formatErrorMessage({
    id: 'form.passwordConfirmation',
    defaultMessage: 'Passwords do not match',
  });

  const schema = useMemo(() => {
    return yup.object().shape({
      password: yup.string().required(fieldIsRequiredMessage),
      newPassword: yup
        .string()
        .required(fieldIsRequiredMessage)
        .notOneOf([yup.ref('password'), null], newPasswordIsEqualToCurrentMessage)
        .matches(
          /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&\/=?_.,:;\\-])).{8,64}$/,
          newPasswordIsInvalidMessage,
        ),
      newPasswordConfirmation: yup
        .string()
        .required(fieldIsRequiredMessage)
        .oneOf([yup.ref('newPassword'), null], newPasswordConfirmationDoesntMatchMessage),
    });
  }, [
    fieldIsRequiredMessage,
    newPasswordIsInvalidMessage,
    newPasswordConfirmationDoesntMatchMessage,
    newPasswordIsEqualToCurrentMessage,
  ]);

  const validatePassword = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'password', value);
    },
    [fieldValidation, schema, data],
  );

  const validateNewPassword = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'newPassword', value);
    },
    [fieldValidation, schema, data],
  );

  const validateNewPasswordConfirmation = useCallback(
    (value: string) => {
      return fieldValidation(schema, data, 'newPasswordConfirmation', value);
    },
    [fieldValidation, schema, data],
  );

  const passwordFields: Array<InputProps> = [
    {
      label: formatAccountMessage({ id: 'password.current', defaultMessage: 'Current password' }),
      name: 'password',
      validationSchema: validatePassword,
      errorMessage: errorCurrentPasswordInvalid
        ? currentPasswordIsWrongMessage
        : errorPasswordChangeGeneric
        ? errorPasswordChangeGenericMessage
        : undefined,
    },
    {
      label: formatAccountMessage({ id: 'password.new', defaultMessage: 'New password' }),
      name: 'newPassword',
      validationSchema: validateNewPassword,
    },
    {
      label: formatAccountMessage({ id: 'password.repeat', defaultMessage: 'Repeat password' }),
      name: 'newPasswordConfirmation',
      validationSchema: validateNewPasswordConfirmation,
    },
  ];

  useEffect(() => {
    setValid(formValidation(schema, data));
  }, [data, formValidation, schema]);

  const passwordTips: string[] = [
    formatAccountMessage({
      id: 'passwordCard.tip.1',
      defaultMessage: 'Ensure a strong, unique password is set for all accounts.',
    }),
    formatAccountMessage({
      id: 'passwordCard.tip.2',
      defaultMessage: 'Use a combination of upper- and lower-case letters, numbers, and symbols in passwords.',
    }),
    formatAccountMessage({
      id: 'passwordCard.tip.3',
      defaultMessage: 'Use easy-to-remember passphrases rather than passwords, that have a minimum of 8 characters.',
    }),
    formatAccountMessage({ id: 'passwordCard.tip.4', defaultMessage: 'Never reuse passwords on multiple accounts.' }),
  ];

  return (
    <div
      id="change-password-modal"
      className={`overflow-y-auto bg-neutral-5 px-20 py-32 max-lg:max-h-[calc(100vh-40px)] max-lg:min-h-fit max-lg:w-[calc(100vw-40px)] lg:px-120`}
    >
      {/* Success */}
      {showSuccess && <Success handleClick={closeModal} />}

      {/* Form */}
      {!showSuccess && (
        <form>
          {/* Title */}
          <Typography className="mb-24 text-20 font-bold lg:mb-40 lg:text-center lg:text-28">
            {formatAccountMessage({ id: 'password.change', defaultMessage: 'Change password' })}
          </Typography>

          {/* Body wrapper */}
          <div className="flex flex-col gap-32">
            {/* Password fields */}
            {passwordFields.map((fieldProps, index) => (
              <PasswordInput
                key={index}
                onChange={handleChange}
                value={data[fieldProps.name as keyof ChangePasswordFormData]}
                required
                {...fieldProps}
              />
            ))}

            {/* Password tips */}
            <div>
              {passwordTips.map((text, i) => {
                return (
                  <div key={i} className="mt-24 flex items-center gap-12 first:mt-0">
                    <span className="h-24 w-24 rounded-full bg-base-accent-1 text-neutral-5">
                      <CheckmarkIcon />
                    </span>
                    <p>{text}</p>
                  </div>
                );
              })}
            </div>

            {/* Action buttons */}
            <div className="flex gap-12 max-lg:flex-col">
              {/* Save */}
              <Button
                type="submit"
                variant="primary"
                className="flex h-48 flex-1 items-center justify-center"
                loading={loading}
                onClick={handleSubmit}
                disabled={loading || !valid}
              >
                {formatCommonMessage({ id: 'save', defaultMessage: 'Save changes' })}
              </Button>

              {/* Cancel */}
              <Button
                type="button"
                variant="secondary"
                className="flex h-48 flex-1 items-center justify-center"
                onClick={closeModal}
                disabled={loading}
              >
                {formatCommonMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ChangePasswordForm;

interface SuccessProps {
  handleClick: () => void;
}

const Success: React.FC<SuccessProps> = ({ handleClick }) => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const text = formatAccountMessage({
    id: 'password.change.success',
    defaultMessage: 'Your password has been changed successfully',
  });
  const label = formatCommonMessage({ id: 'close', defaultMessage: 'Close' });

  return (
    <div className="">
      <div className="flex justify-center">
        <ChangePasswordSuccessIcon />
      </div>

      <Typography className="mt-32 text-center text-28 font-bold">{text}</Typography>

      <Button
        type="button"
        variant="secondary"
        className="mt-24 flex h-48 w-full flex-1 items-center justify-center"
        onClick={handleClick}
      >
        {label}
      </Button>
    </div>
  );
};
