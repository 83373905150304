import { FC, useState } from 'react';
import Modal from 'components/commercetools-ui/atoms/modal';
import ChevronRightIcon from 'components/icons/ChevronRightIcon';
import PasswordIcon from 'components/icons/PasswordIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { lg } from 'helpers/utils/screensizes';
import InfoCard from '../../../account-atoms/info-card';
import ChangePasswordForm from '../forms/change-password-form';

const PasswordCard: FC = () => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const [isAboveLg] = useMediaQuery(lg);
  const [showModal, setShowModal] = useState(false);

  const header = {
    icon: <PasswordIcon />,
    title: formatAccountMessage({
      id: 'passwordCard.title',
      defaultMessage: 'Password',
    }),
    iconRight: <ChevronRightIcon />,
  };

  const handleClick = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <button onClick={handleClick}>
        <InfoCard header={header} />
      </button>

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        closeTimeoutMS={200}
        closeButton={isAboveLg}
        preventScroll
        style={{
          content: {
            top: isAboveLg ? '50%' : '20px',
            left: isAboveLg ? '50%' : '20px',
            transform: isAboveLg ? 'translate(-50%, -50%)' : 'none',
            width: isAboveLg ? '' : 'fit-content',
          },
        }}
      >
        <ChangePasswordForm closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default PasswordCard;
