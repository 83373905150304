import { FC } from 'react';
import { Account } from 'shared/types/account';
import UserIcon from 'components/icons/UserIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import InfoCard from '../../../account-atoms/info-card';

interface Props {
  account: Account;
}

const PersonalCard: FC<Props> = ({ account }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const phone = account.addresses?.find((address) => address.isDefaultBillingAddress)?.phone ?? '-';

  const header = {
    icon: <UserIcon />,
    title: formatAccountMessage({
      id: 'personalCard.title',
      defaultMessage: 'Personal information',
    }),
  };

  const fields: Array<{ label: string; value: string }> = [
    { label: formatMessage({ id: 'firstName', defaultMessage: 'First Name' }), value: account?.firstName as string },
    { label: formatMessage({ id: 'lastName', defaultMessage: 'Last Name' }), value: account?.lastName as string },
    { label: formatMessage({ id: 'email', defaultMessage: 'Email' }), value: account?.email },
    { label: formatMessage({ id: 'phone', defaultMessage: 'Phone' }), value: phone },
    {
      label: formatMessage({ id: 'preferredStore', defaultMessage: 'Home hall' }),
      value: account?.preferredStoreName || '-',
    },
  ];

  return (
    <InfoCard header={header}>
      <div className="flex flex-col gap-16">
        {fields.map((field, i) => {
          return (
            <div key={i} className="flex flex-col gap-8 text-neutral-4">
              <p className="font-bold">{field.label}</p>
              <p>{field.value}</p>
            </div>
          );
        })}
      </div>
    </InfoCard>
  );
};

export default PersonalCard;
