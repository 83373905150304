import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';

interface Props {
  header: {
    icon: JSX.Element;
    title: string;
    iconRight?: JSX.Element;
  };
  children?: React.ReactNode;
}

const InfoCard: React.FC<Props> = ({ header: { title, icon, iconRight }, children }) => {
  return (
    <div className={`flex flex-col gap-24 border border-base-accent-2 bg-neutral-5 px-20 py-24`}>
      {/* Header */}
      <div className="flex items-center justify-between gap-12">
        {/* Left */}
        <div className="flex items-center gap-8">
          <span className="text-base-accent-1">{icon}</span>
          <Typography className="text-18 font-bold text-neutral-4">{title}</Typography>
        </div>

        {/* Right */}
        {iconRight}
      </div>

      {/* Body */}
      {children}
    </div>
  );
};

export default InfoCard;
