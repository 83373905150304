import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Account } from 'shared/types/account';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import AccountCard from './sections/account-card';
import CompanyCard from './sections/company-card';
import MarketingCard from './sections/marketing-card';
import PasswordCard from './sections/password-card';
import PersonalCard from './sections/personal-card';

interface Props {
  isLoading?: boolean;
}

const MyAccount: React.FC<Props> = ({ isLoading }) => {
  const { account: acc } = useAccount();
  const account = acc as Account;
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const title = formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' }) + account?.firstName ?? '';
  const subtitle = formatAccountMessage({
    id: 'account.sidebar.description',
    defaultMessage: 'Manage your account details.',
  });

  return (
    <div className="px-20 pb-32 md:py-32">
      <div className="">
        {isLoading ? (
          <Skeleton className="h-[350px]" />
        ) : (
          <>
            <div className="hidden text-neutral-4 md:flex md:flex-col md:gap-12">
              <Typography className="text-24 font-bold">{title}</Typography>
              <Typography>{subtitle}</Typography>
            </div>

            <div className="flex flex-col gap-12 md:mt-24">
              <AccountCard account={account} />
              {account.nipId && <CompanyCard account={account} />}
              <PersonalCard account={account} />
              <MarketingCard account={account} />
              <PasswordCard />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyAccount;
