import React, { Fragment, useCallback } from 'react';
import { Transition, Menu } from '@headlessui/react';
import ExclamationMarkIcon from 'components/icons/ExclamationMarkIcon';
import Typography from '../typography';

export interface CustomDropdownProps {
  buttonElement?: JSX.Element;
  buttonClassNames?: (open?: boolean) => string | string;
  menuWrapperClassNames?: string;
  menuClassNames?: (open?: boolean) => string | string;
  isValid?: boolean;
  errorMessage?: string;
}

const CustomDropDown = ({
  buttonElement,
  buttonClassNames,
  menuWrapperClassNames,
  menuClassNames,
  isValid = true,
  errorMessage,
  children,
}: React.PropsWithChildren<CustomDropdownProps>) => {
  const defaultButtonClassNames = useCallback(() => {
    return `${isValid ? 'border-neutral-2' : 'border-status-danger-border'}
    cursor-pointer flex h-48 w-full items-center justify-between border rounded-full py-8 px-20 bg-neutral-5 !text-16 !leading-5`;
  }, [isValid]);

  const defaultMenuClassNames = useCallback((open?: boolean) => {
    return `max-h-[172px] mt-16 overflow-y-scroll no-scrollbar rounded-[20px] !text-16 !leading-5 ${
      open ? `border border-neutral-2 py-16 [&>div:hover]:!bg-base-accent-3 [&>div>*]:!px-20 [&>div>*]:!py-12` : ''
    } bg-white`;
  }, []);

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button as="div" className={buttonClassNames ? buttonClassNames(open) : defaultButtonClassNames()}>
            {buttonElement}
          </Menu.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform origin-top scale-y-0"
            enterTo="transform origin-top scale-y-100"
            leave="transition ease-in duration-100"
            leaveFrom="transform origin-top scale-y-150"
            leaveTo="transform origin-top scale-y-0"
          >
            <Menu.Items
              className={menuWrapperClassNames ?? 'absolute left-0 top-40 z-10 w-full focus-visible:outline-none'}
            >
              <div className={menuClassNames ? menuClassNames(open) : defaultMenuClassNames(open)}>{children}</div>
            </Menu.Items>
          </Transition>

          {!isValid && errorMessage && (
            <div className="mt-12 flex items-center gap-4">
              <span className="text-status-danger-border">
                <ExclamationMarkIcon />
              </span>
              <Typography className="text-14 text-status-danger-border" as="span">
                {errorMessage}
              </Typography>
            </div>
          )}
        </>
      )}
    </Menu>
  );
};

export default CustomDropDown;
