function PencilIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M5.154 19H6.39l9.26-9.26-1.237-1.236-9.26 9.26V19zM18.858 8.656l-3.375-3.344 1.303-1.304c.294-.294.651-.44 1.072-.44.42 0 .777.146 1.07.44l1.218 1.217c.294.294.445.646.456 1.056.01.41-.132.762-.425 1.056l-1.32 1.319zm-1.085 1.1L7.029 20.5H3.654v-3.375L14.398 6.381l3.375 3.375zm-2.746-.639l-.614-.613L15.65 9.74l-.623-.623z"
      ></path>
    </svg>
  );
}

export default PencilIcon;
