import React, { FC } from 'react';
import { OrderHistory } from '../../..';

interface Props {
  studioData: OrderHistory;
}

const OrderDescription: FC<Props> = ({ studioData }) => {
  const {
    returnAndComplaintsHeadline,
    returnAndComplaintsDescription,
    orderCancellationHeadline,
    orderCancellationDescription,
    orderModificationHeadline,
    orderModificationDescription,
  } = studioData;
  return (
    <div className="flex flex-col gap-32 bg-neutral-5 px-24 py-32">
      {/* Returns and complaints */}
      <div className="flex flex-col gap-12">
        <p className="text-16 font-bold leading-5 text-neutral-4 md:text-20 md:leading-[25px]">
          {returnAndComplaintsHeadline}
        </p>
        <p className="text-16 leading-6 text-neutral-4">{returnAndComplaintsDescription}</p>
      </div>

      {/* Order cancellation */}
      <div className="flex flex-col gap-12">
        <p className="text-16 font-bold leading-5 text-neutral-4 md:text-20 md:leading-[25px]">
          {orderCancellationHeadline}
        </p>
        <p className="text-16 leading-6 text-neutral-4">{orderCancellationDescription}</p>
      </div>

      {/* Order modification */}
      <div className="flex flex-col gap-12">
        <p className="text-16 font-bold leading-5 text-neutral-4 md:text-20 md:leading-[25px]">
          {orderModificationHeadline}
        </p>
        <p className="text-16 leading-6 text-neutral-4">{orderModificationDescription}</p>
      </div>
    </div>
  );
};

export default OrderDescription;
