import React, { FC } from 'react';
import { useParams } from 'next/navigation';
import { Order } from 'shared/types/cart/Order';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import mapCosts from 'helpers/utils/mapCosts';
import { useP24Transaction } from 'frontastic/hooks/useP24Transaction';

interface Props {
  order?: Order;
}

const OrderItem: FC<Props> = ({ order }) => {
  const { urlReturn, error, loading } = useP24Transaction(order?.orderId ?? '');
  const { currency } = useI18n();
  const { locale } = useParams();

  const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
  const { total: getTotal } = mapCosts({ order, currency });

  const orderDate = order?.createdAt && new Date(order?.createdAt).toISOString().split('T')[0];

  return (
    <div className="w-full border border-neutral-2 bg-neutral-5 px-16 py-20 lg:p-20">
      <div className="grid grid-cols-1 border-b border-b-neutral-2 bg-neutral-5 lg:grid-cols-5 lg:items-center lg:pb-16 ">
        {/* Order number */}
        <div className="grid grid-cols-2 lg:flex lg:flex-col">
          <Typography className="pb-8 font-bold text-neutral-4">
            {formatOrdersMessage({ id: 'order.id', defaultMessage: 'Order ID ' })}
          </Typography>
          <Typography className="text-14 text-neutral-4 max-md:text-16">
            {order?.orderId?.replaceAll('-', ' ')}
          </Typography>
        </div>

        {/* Order date */}
        <div className="grid grid-cols-2 lg:flex lg:flex-col">
          <Typography className="pb-8 font-bold text-neutral-4">
            {formatOrdersMessage({ id: 'order.date', defaultMessage: 'Date:' })}
          </Typography>
          <Typography className="text-14 text-neutral-4 max-md:text-16">{orderDate}</Typography>
        </div>
        {/* Order total amount */}
        <div className="grid grid-cols-2 lg:flex lg:flex-col">
          <Typography className="pb-8 font-bold  text-neutral-4">
            {formatOrdersMessage({ id: 'total', defaultMessage: 'Total' })}
          </Typography>
          <Typography className="text-14 text-neutral-4 max-md:text-16">
            {CurrencyHelpers.formatForCurrency(getTotal, locale)}
          </Typography>
        </div>

        {/* Order status */}
        <div className="grid grid-cols-2 lg:flex lg:flex-col">
          <Typography className="pb-8 font-bold text-neutral-4">
            {formatOrdersMessage({ id: 'status', defaultMessage: 'Status' })}
          </Typography>
          <Typography className="text-14 text-neutral-4 max-md:text-16">
            {formatOrdersMessage({ id: order?.orderState as string, defaultMessage: order?.orderState })}
          </Typography>
        </div>
        <div className="hidden whitespace-nowrap lg:ml-48 lg:flex lg:flex-col">
          {order?.paymentMethod === 'P24' && order?.orderState === 'Open' && !error && (
            <Link variant="button-primary" link={urlReturn}>
              {loading ? (
                <span className="flex justify-center [&>span>svg]:hidden">
                  <LoadingIcon className={'fill-white'} />
                </span>
              ) : (
                <Typography className="flex justify-center">
                  {formatOrdersMessage({ id: 'payNow', defaultMessage: 'Pay now' })}
                </Typography>
              )}
            </Link>
          )}
        </div>
      </div>
      {/* Order details and product amount */}
      <Link link={`?hash=orders&id=order_${order?.orderId}`}>
        <div className="grid w-full cursor-pointer grid-cols-2 pt-16 lg:flex lg:items-center lg:justify-between">
          <div className="flex">
            <Typography className="text-16 font-bold text-neutral-4">{order?.lineItems?.length.toString()}</Typography>
            <Typography className="pl-7 text-16 font-bold text-neutral-4">
              {formatOrdersMessage({ id: 'articles', defaultMessage: 'articles' })}
            </Typography>
          </div>

          <div className="flex items-center gap-4 text-neutral-4">
            <ArrowRightIcon />
            <span className="text-16 leading-5">
              {formatOrdersMessage({ id: 'showDetails', defaultMessage: 'Show details' })}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default OrderItem;
