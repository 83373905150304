import React, { FC, useMemo, useState } from 'react';
import { LineItem } from 'shared/types/cart/LineItem';
import Accordion from 'components/commercetools-ui/atoms/accordion';
import ChevronDownIcon from 'components/icons/ChevronDownIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import ClosedButton from './closed-button';
import OrderItem from './order-item';
import OrderItemsList from './order-items-list';

type OrderItemsListingProps = {
  className?: string;
  lineItems: LineItem[];
};

const OrderItemsListing: FC<OrderItemsListingProps> = ({ className, lineItems }) => {
  const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
  const [isDesktopSize] = useMediaQuery(desktop);
  const [open, setOpen] = useState(false);

  const containerClassName = useClassNames(['grid w-full grid-cols-1', className]);
  const accordionClassNames = useClassNames([open ? 'pt-8 shadow-custom-top' : 'lg:pt-32 md:pt-24', , className]);

  const hiddenItemsCount = useMemo(() => (lineItems?.length ?? 0) - 3, [lineItems?.length]);
  const arrowClassNames = useClassNames([
    open ? 'rotate-180 transform' : '',
    'transition',
    'absolute right-12 top-24 lg:hidden',
  ]);

  return (
    <>
      {lineItems.length === 1 ? (
        <div className={containerClassName}>
          <OrderItem additionalClassName="border-none" lineItem={lineItems[0]} />
        </div>
      ) : (
        <Accordion
          closedSectionTitle={formatOrdersMessage({ id: 'your.order', defaultMessage: 'Your order' })}
          customClosedButton={
            isDesktopSize ? (
              <ClosedButton hiddenItemsCount={hiddenItemsCount} lineItems={lineItems} open={open} />
            ) : (
              <div className={arrowClassNames}>
                <ChevronDownIcon />
              </div>
            )
          }
          className={accordionClassNames}
          buttonClassName="w-full"
          onClick={() => setOpen(!open)}
        >
          <OrderItemsList lineItems={lineItems} />
        </Accordion>
      )}
    </>
  );
};

export default OrderItemsListing;
