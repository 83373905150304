function PasswordIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M2.25 18.75v-1.5h19.5v1.5H2.25zm.842-6.011l-1.03-.596.907-1.597H1.154V9.354h1.815l-.907-1.546 1.03-.596L4 8.758l.908-1.546 1.03.596-.907 1.546h1.815v1.193H5.031l.907 1.596-1.03.596L4 11.143l-.908 1.596zm8 0l-1.03-.596.907-1.597H9.154V9.354h1.815l-.907-1.546 1.03-.596L12 8.758l.908-1.546 1.03.596-.907 1.546h1.815v1.193h-1.815l.907 1.596-1.03.596L12 11.143l-.908 1.596zm8 0l-1.03-.596.907-1.597h-1.815V9.354h1.815l-.908-1.546 1.031-.596L20 8.758l.908-1.546 1.03.596-.907 1.546h1.815v1.193h-1.815l.907 1.596-1.03.596L20 11.143l-.908 1.596z"
      ></path>
    </svg>
  );
}

export default PasswordIcon;
