import { FC, useMemo } from 'react';
import { LineItem } from 'shared/types/cart/LineItem';
import Typography from 'components/commercetools-ui/atoms/typography';
import ChevronDownIcon from 'components/icons/ChevronDownIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import Image from 'frontastic/lib/image';

type ClosedButtonProps = {
  hiddenItemsCount: number;
  lineItems: LineItem[];
  open: boolean;
};

const ClosedButton: FC<ClosedButtonProps> = ({ lineItems, hiddenItemsCount, open }) => {
  const arrowClassNames = useClassNames([open ? 'rotate-180 transform' : '', 'transition']);

  const lineItemOrderSummary = useMemo(() => {
    if (lineItems.length < 3) return lineItems;
    else return [lineItems[0], lineItems[1], lineItems[2]];
  }, [lineItems]);

  return (
    <div className="flex w-full flex-col overflow-x-visible pb-4">
      <div className="flex w-full justify-between">
        {!open && (
          <div className="flex items-center justify-between gap-12">
            {lineItemOrderSummary?.map((lineItem) => (
              <div key={lineItem?.lineItemId}>
                {lineItem?.variant?.images?.[0] && (
                  <div key={lineItem?.lineItemId} className="relative h-[80px] w-[80px] shrink-0 border">
                    <Image
                      fill
                      src={lineItem.variant.images[0]}
                      style={{ objectFit: 'contain', padding: '8px' }}
                      alt={lineItem.name}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div className={` ${open ? 'w-full' : 'h-[80px] w-[80px]'} ml-12 flex items-center justify-end gap-8 p-8 pr-0`}>
          {hiddenItemsCount > 0 && !open && (
            <Typography className="whitespace-nowrap text-neutral-4">{`+ ${hiddenItemsCount}`}</Typography>
          )}
          <div className={arrowClassNames}>
            <ChevronDownIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClosedButton;
