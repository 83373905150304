import React, { ChangeEvent, ComponentProps, FC } from 'react';
import ChevronDownIcon from 'components/icons/ChevronDownIcon';
import ExclamationMarkIcon from 'components/icons/ExclamationMarkIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import Typography from '../typography';

export interface DropdownProps extends ComponentProps<'select'> {
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  items: Array<{ label: string; value: string }>;
  label?: string;
  value?: string;
  defaultValue?: string;
  errorMessage?: string;
  isValid?: boolean;
}

const DefaultDropdown: FC<DropdownProps> = ({
  isValid = true,
  className = '',
  containerClassName = '',
  labelClassName,
  label,
  items,
  onChange,
  errorMessage,
  ...props
}) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange?.(e);
  };

  const containerClassNames = useClassNames(['grid', containerClassName]);

  const selectClassName = useClassNames([
    !isValid ? 'border-status-danger-border' : 'border-neutral-2 focus:border-neutral-2',
    'absolute z-[1] h-48 text-16 leading-5 rounded-full border focus:ring-0 w-full bg-neutral-5 leading-5 py-8 px-20',
    className,
  ]);

  const labelClassNames = useClassNames(['mb-8 text-neutral-4 leading-5 text-16', labelClassName]);

  return (
    <div className={containerClassNames}>
      {label && (
        <Typography as="label" className={labelClassNames}>
          {`${label}${props.required ? ' *' : ''}`}
        </Typography>
      )}

      <div className="no-scrollbar relative h-48 min-w-[64px] overflow-y-scroll">
        <select className={selectClassName} onChange={handleChange} {...props}>
          {items.map(({ label, value }, index) => (
            <option key={value + index} value={value} className="text-16 leading-5">
              {label}
            </option>
          ))}
        </select>

        <span className="pointer-events-none absolute right-4 top-[50%] z-10 translate-y-[-50%] bg-neutral-5 pr-17 text-neutral-4">
          <ChevronDownIcon />
        </span>
      </div>
      {!isValid && errorMessage && (
        <div className="mt-12 flex items-center gap-4">
          <span className="text-status-danger-border">
            <ExclamationMarkIcon />
          </span>
          <Typography className="text-14 text-status-danger-border" as="span">
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default DefaultDropdown;
