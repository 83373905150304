function ChangePasswordSuccessIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={scale * 148} height={scale * 142} fill="none" viewBox="0 0 148 142">
      <defs>
        <linearGradient id="gradient" x1="74.5" x2="74.5" y1="12.363" y2="126.636" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EC702E"></stop>
          <stop offset="1" stopColor="#F8CC46"></stop>
        </linearGradient>
      </defs>
      <path
        fill="#CEEBFF"
        d="M142.032 65.682c3.281 29.543-39.271 71.05-77.865 75.337-38.593 4.287-43.452-26.016-53.34-49.987C-.247 64.185-3.576 40.329 39.676 36.939c30.206-2.368 94.164-12.466 102.356 28.743z"
      ></path>
      <path
        fill="url(#gradient)"
        d="M56.033 126.636l-10.335-17.419-19.625-4.3 1.916-20.203L14.69 69.5l13.298-15.214-1.916-20.203 19.625-4.3 10.335-17.419 18.467 7.84 18.467-7.84 10.335 17.419 19.625 4.3-1.916 20.203L134.309 69.5l-13.298 15.214 1.916 20.203-19.625 4.3-10.335 17.419-18.467-7.841-18.467 7.841zm3.698-11.093l14.769-6.259 14.947 6.259 8.22-13.9 15.927-3.653-1.448-16.328 10.714-12.163-10.714-12.34 1.448-16.328-15.927-3.475-8.398-13.9-14.769 6.26-14.947-6.26-8.22 13.9-15.927 3.475 1.448 16.328-10.714 12.34 10.714 12.163-1.448 16.506 15.927 3.475 8.398 13.9zm8.688-27.488l30.717-30.718-6.103-6.281L68.42 75.671 55.967 63.396 49.864 69.5l18.555 18.556z"
      ></path>
    </svg>
  );
}

export default ChangePasswordSuccessIcon;
