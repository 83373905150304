import { useEffect, useState } from 'react';
import { sdk } from 'sdk';

export const useP24Transaction = (orderId: string) => {
  const [urlReturn, setUrlReturn] = useState<string>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUrlReturn = async () => {
      setLoading(true);
      const res = (await sdk.callAction({
        actionName: 'przelewy24/registerTransaction',
        payload: {
          commercetoolsOrderId: orderId,
          returnTo: 'orderDetail',
        },
      })) as any;
      if (!res.isError && res.data.p24Url) {
        setUrlReturn(res.data.p24Url);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    };
    if (orderId) {
      fetchUrlReturn();
    }
  }, [orderId]);

  return { urlReturn, error, loading };
};
