import React, { FC } from 'react';
import { OrderStatus } from '.';

interface OrderStatusesTableProps {
  orderStatuses: OrderStatus[];
}

const OrderStatusesTable: FC<OrderStatusesTableProps> = ({ orderStatuses }) => {
  return (
    <table className="w-full table-fixed">
      <tbody>
        {orderStatuses.map((orderStatus) => (
          <tr key={orderStatus.status} className="border-b border-neutral-2 last:border-none">
            <td className="w-1/2 break-words border-r px-12 py-10 text-left text-16 leading-6 md:text-14 md:leading-[17.5px]">
              {orderStatus.status}
            </td>
            <td className="w-1/2 px-12 py-10 text-left">
              <p className="text-16 leading-6 md:text-14 md:leading-[17.5px]">{orderStatus.description}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default OrderStatusesTable;
