import { FC } from 'react';
import { Account } from 'shared/types/account';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import ClipboardIcon from 'components/icons/ClipboardIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import InfoCard from '../../../account-atoms/info-card';

interface Props {
  account: Account;
}

const MarketingCard: FC<Props> = ({ account }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const header = {
    icon: <ClipboardIcon />,
    title: formatAccountMessage({
      id: 'marketingCard.title',
      defaultMessage: 'Marketing Content & Consent',
    }),
  };

  const description = formatAccountMessage({
    id: 'marketingCard.description',
    defaultMessage:
      'I want to receive commercial information, offers, non-public advertising of alcoholic beverages and other marketing or promotional communications to the contact details provided by me in the application form or later',
  });

  const fields: Array<{ name: string; label: string; checked: boolean }> = [
    {
      name: 'email',
      label: formatAccountMessage({
        id: 'marketingCard.emailConsent',
        defaultMessage:
          'Electronically to the e-mail address provided by me within the meaning of the Act of July 18, 2002 on the provision of electronic services.',
      }),
      checked: account.emailMarketingAccepted,
    },
    {
      name: 'sms',
      label: formatAccountMessage({
        id: 'marketingCard.smsConsent',
        defaultMessage:
          'By text message to the telephone number provided by me within the meaning of the Act of July 18, 2002 on the provision of electronic services.',
      }),
      checked: account.smsMarketingAccepted,
    },
    {
      name: 'phone',
      label: formatAccountMessage({
        id: 'marketingCard.phoneConsent',
        defaultMessage:
          'By phone to the telephone number provided by me within the meaning of the Telecommunications Law Act of 16 July 2004.',
      }),
      checked: account.phoneMarketingAccepted,
    },
  ];

  return (
    <InfoCard header={header}>
      <div className="flex flex-col gap-16 lg:gap-24">
        <p className="font-bold">{description}</p>
        {fields.map(({ name, label, checked }, i) => {
          return (
            <div key={i} className="flex flex-col gap-8 text-neutral-4">
              <label key={i} className="mt-12 flex items-start gap-12 first:mt-0">
                <Checkbox containerClassName="mt-2" name={name} checked={checked} disabled />
                <p>{label}</p>
              </label>
            </div>
          );
        })}
      </div>
    </InfoCard>
  );
};

export default MarketingCard;
